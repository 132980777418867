$(document).ready(function(){

  $('.m-menu').click(function(){
    $('html, body').toggleClass('noscroll');
    $('body').toggleClass('m-menu-open');
  });


  $('.up').click(function(){
    $("html, body").animate({ scrollTop: "0" });
  });

  $('.add-reaction').click(function(){
    $('.comments').addClass('form-open');
  });
  $('.close').click(function(){
    $('.comments').removeClass('form-open');
  });

  $('.cc-w-i').change(function(){
    //console.log('comments changed');
    $('.comments').removeClass('form-open');
  });

  $('.gallery').slick();

  // scrolling stuff

  var scrolled = 0;
  function handleScroll () {
    scrolled = window.scrollY;
    var scrollOffsetFirst = 200;
    var scrollOffset = 300;
    if(scrolled > scrollOffsetFirst){
      document.querySelector('body').classList.add('prescrolling');
    }else{
      document.querySelector('body').classList.remove('prescrolling');
    }
    if(scrolled > scrollOffset){
      document.querySelector('body').classList.add('scrolling');
    }else{
      document.querySelector('body').classList.remove('scrolling');
    }
  }

  window.addEventListener('scroll', handleScroll);


});